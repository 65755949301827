import { useStaticQuery, graphql, Link } from 'gatsby';
import React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import slugify from '../../util/slugify';

const GovSystemList = () => {
  const data = useStaticQuery(graphql`
    {
      allGovSystemsJson(sort: { order: ASC, fields: country }) {
        nodes {
          jsonId
          abbr
          country
          link
          emoji
        }
      }
      allGovComponentsJson {
        nodes {
          jsonId
        }
      }
    }
  `);

  const { allGovSystemsJson, allGovComponentsJson } = data;
  const idsWithComponents = allGovComponentsJson.nodes.map(
    ({ jsonId }) => jsonId
  );

  return (
    <div className="max-w-xl">
      <ul>
        {allGovSystemsJson.nodes.map(
          ({ jsonId, abbr, country, link, emoji }) => (
            <li key={jsonId} className="py-1">
              <div className="flex items-center  ">
                <div title={abbr} className="text-4xl">
                  {emoji}
                </div>
                <div className="ml-3 flex w-full items-center justify-between">
                  {` ${country}`}
                  <div className="flex items-center">
                    {idsWithComponents.includes(jsonId) && (
                      <>
                        <Link
                          to={`/gov-systems/${slugify(jsonId)}`}
                          className="rounded px-2 py-1 font-semibold text-teal-600 transition-colors duration-200 ease-in-out hover:bg-teal-200/50 focus:outline-none focus:ring focus:ring-teal-500"
                        >
                          Components
                        </Link>
                        <span className="px-1 text-xl text-slate-300 md:px-3">
                          •
                        </span>
                      </>
                    )}
                    <a
                      href={link}
                      title="Desing System Website"
                      className="rounded px-2 py-1 transition-colors duration-200 ease-in-out hover:bg-teal-200/50 focus:outline-none focus:ring focus:ring-teal-500"
                    >
                      <ArrowTopRightOnSquareIcon className="h-5 w-5  text-slate-500" />
                    </a>
                  </div>
                </div>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default GovSystemList;
