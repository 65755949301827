import React from 'react';
import GovSystemList from '../components/index/GovSystemList';
import Layout from '../components/layout';
import SEO from '../components/seo';
import EmailDisplay from '../EmailDisplay';

const IndexPage = () => (
  <Layout footer={false} bodyMargin={false}>
    <SEO title="Home" />
    <div className="flex flex-col tablet:h-[calc(100vh-4rem)] tablet:flex-row">
      <aside className="left-0 order-last flex flex-col  justify-between overscroll-contain border-r border-slate-200 p-4 tablet:order-first tablet:w-1/3">
        <div>
          <h1 className="mb-5 text-3xl font-semibold text-orange-600">
            Design Systems for the Web
          </h1>
          <div className="mt-8 pr-3">
            <figure className="prose">
              <blockquote>
                Design systems lay out the core elements and components of
                visual and interactive design for the devices we use every day
                [...]. They offer guidance for layout, shape/form, color
                palettes, buttons, forms, tables, typography, user task flows,
                and can offer advice for content, branding, tone, and
                personality. Design systems enable designers and developers to
                create a consistent and cohesive product or suite of products.
              </blockquote>
              <figcaption>
                <a href="https://dl.acm.org/doi/fullHtml/10.1145/3352681">
                  <cite>
                    Churchill, E. F. (2019). Scaling UX with design systems.
                    Interactions, 26(5), 22-23.
                  </cite>
                </a>
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="text-slate-600">
          <span>
            By{' '}
            <a
              href="https://hartenfeller.dev"
              className="text-slate-800 underline hover:text-black"
            >
              Philipp Hartenfeller
            </a>
          </span>
          <span> • </span>
          <a href="https://hartenfeller.dev/imprint">Imprint</a>
          <span> • </span>
          <a href="https://hartenfeller.dev/privacy">Privacy Policy</a>
          <span> • </span>
          <span>No Cookies are used</span>
          <span> • </span>
          <EmailDisplay />
        </div>
      </aside>

      <div className="overflow-y-scroll overscroll-contain p-4 tablet:max-h-[calc(100vh-4rem)] tablet:w-2/3">
        <h2 className="mb-3 font-mono text-xl font-semibold text-slate-700 underline decoration-slate-400">
          Government Design Systems
        </h2>
        <GovSystemList />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
