import React, { useEffect, useState } from 'react';

function decode(str) {
  const ascii = str.split('|');
  const intermediate = String.fromCharCode(...ascii);
  return atob(intermediate);
}

const EmailDisplay = () => {
  const [email, setEmail] = useState('loading...');

  useEffect(() => {
    setTimeout(() => {
      const encoded =
        '89|50|57|117|100|71|70|106|100|69|66|51|90|87|74|107|90|88|78|112|90|50|53|122|101|88|78|48|90|87|49|122|76|109|57|121|90|119|61|61';

      setEmail(decode(encoded));
    }, 2000);
  }, [setEmail]);

  return <a href={`mailto:${email}`}>Contact</a>;
};

export default EmailDisplay;
